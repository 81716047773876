import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { Input } from "components/Shared";
import { DialogContent } from "components/Shared/Dialog";
import { useCallback, useContext, useMemo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { CalendarIcon } from "styles/components";
import { Status } from "components/Pages/Invoices";
import * as S from "./styles";
import { Formatter } from "utils";
import { UpdateInvoiceDeliveryDatePayload } from "contracts/Invoice";
import { UpdateInvoiceDeliveryDateActions } from "store/ducks/pendingRelease";
import { ReleasedContext } from "contexts/ReleasedContext";
import { useValidation } from "hooks";
import { isAfter } from "date-fns";
import { notify } from "services";

interface FormData {
  justificationId: number;
  deliveryDate: string;
  description?: string | null;
  emissionDate?: string | null;
}

type AddDeliveryDateModalProps = {
  onRefresh?: () => void;
};

const AddDeliveryDate = ({ onRefresh }: AddDeliveryDateModalProps) => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(ReleasedContext);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: RootState) => state.updateInvoiceDeliveryDate
  );
  const { handleFormErrors } = useValidation();

  const EmissionDate = selectedList.reduce(
    (acc, curr) =>
      isAfter(new Date(acc), new Date(curr.infnfeIdeDhemi))
        ? acc
        : curr.infnfeIdeDhemi,
    selectedList[0].infnfeIdeDhemi
  );

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      prev: invoice.deadlineDate
        ? Formatter.date(invoice.deadlineDate, { format: "dd/MM/yyyy HH:mm" })
        : "---",
      status:
        invoice.status?.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
    }));
  }, [selectedList]);

  useEffect(() => {
    formRef.current?.setFieldValue(
      "deliveryDate",
      Formatter.date(new Date().toISOString(), { format: "yyyy-MM-dd" })
    );
  }, []);

  const handleSubmit = useCallback<SubmitHandler>(
    (data: FormData) => {
      try {
        const canceledInvoices = selectedList.filter(
          (invoice) => invoice.canceledAt !== null
        );
        if (canceledInvoices.length > 0) {
          return notify(
            "error",
            "Não é permitido alterar a data de entrega para notas canceladas."
          );
        }
        const deliveryDatePayload: UpdateInvoiceDeliveryDatePayload = {
          deliveryDate: Formatter.dateToISO(data.deliveryDate, {
            format: "yyyy-MM-dd HH:mm:ss",
          }),
          description: data.description,
          ids: selectedList.map((invoice) => invoice.id),
        };

        if (!data.description) {
          return notify("error", "Justificativa não preenchida");
        }

        dispatch(
          UpdateInvoiceDeliveryDateActions.request(deliveryDatePayload, () => {
            onQueryChange({}, true);
            clearSelectList();
            changeOpenModal();
            if (onRefresh) onRefresh();
          })
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      changeOpenModal,
      clearSelectList,
      dispatch,
      onQueryChange,
      onRefresh,
      selectedList,
    ]
  );

  return (
    <DialogContent
      container={refModal}
      title="Adicionar data de entrega para as notas listadas abaixo"
      icon={<CalendarIcon />}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>P. Contratado</span>
            <span>Status</span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
          <Input
            name="deliveryDate"
            type="datetime-local"
            min={Formatter.date(EmissionDate, { format: "yyyy-MM-dd HH:mm" })}
            max={Formatter.date("2100-12-31T23:59", {
              format: "yyyy-MM-dd HH:mm",
            })}
            label="Nova Data de Entrega"
            onInvalid={(e) => {
              (e.target as HTMLInputElement).setCustomValidity('Data inválida!');
            }}
            onInput={(e) => {
              (e.target as HTMLInputElement).setCustomValidity('');
            }}
          />
          <Input name="description" label="Justificativa" />
          <S.Button type="submit" disabled={loading}>
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { AddDeliveryDate };
