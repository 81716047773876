import { useCallback, useContext } from "react";
import { Dialog, DialogTrigger } from "components/Shared/Dialog";
import * as S from "./styles";
import { ReleasedContext, ModalTypes } from "contexts/ReleasedContext";
import { AddJustificationsModal } from "../AddJustificationsModal";
import { AddDeadlineModal } from "../AddDeadlineModal";
import { usePermission } from "hooks/usePermission";
import { AddDeliveryDate } from "../AddDeliveryDate";

const DropdownMenuInvoices = () => {
  const { openedModal, changeOpenModal, selectedList } =
    useContext(ReleasedContext);
  const { hasPermissionTo } = usePermission();

  const modalIsOpen = openedModal === "justificativas";

  const handleModal = useCallback(
    (event: Event, type: ModalTypes) => {
      event.preventDefault();
      changeOpenModal(type);
    },
    [changeOpenModal]
  );

  return (
    <S.Box>
      <S.DropdownMenuRoot>
        <S.Trigger asChild disabled={!selectedList.length}>
          <S.IconButton
            aria-label="Customise options"
            clicable={selectedList.length > 0}
          >
            <S.Icon size={25} />
          </S.IconButton>
        </S.Trigger>

        {selectedList.length > 0 && (
          <S.Portal>
            <S.Content sideOffset={-1} side="bottom" align="end">
              <Dialog
                open={modalIsOpen}
                key="justificativas"
                onOpenChange={(open) => !open && changeOpenModal()}
              >
                <DialogTrigger asChild>
                  <S.Item
                    onSelect={(e: Event) => handleModal(e, "justificativas")}
                  >
                    Adicionar Justificativas
                  </S.Item>
                </DialogTrigger>
                {modalIsOpen && <AddJustificationsModal />}
              </Dialog>

              {hasPermissionTo(["TRACKING.UPDATE_DEADLINE_DATE"]) && (
                <Dialog
                  open={openedModal === "prazo"}
                  key="prazo"
                  onOpenChange={(open) => !open && changeOpenModal()}
                >
                  <DialogTrigger asChild>
                    <S.Item onSelect={(e: Event) => handleModal(e, "prazo")}>
                      Alterar prazo contratado
                    </S.Item>
                  </DialogTrigger>
                  {openedModal === "prazo" && <AddDeadlineModal />}
                </Dialog>
              )}
              {hasPermissionTo(["TRACKING.UPDATE_DELIVERY_DATE"]) && (
                <Dialog
                  open={openedModal === "deliveryDate"}
                  key="deliveryDate"
                  onOpenChange={(open) => !open && changeOpenModal()}
                >
                  <DialogTrigger asChild>
                    <S.Item
                      onSelect={(e: Event) => handleModal(e, "deliveryDate")}
                    >
                      Data de Entrega
                    </S.Item>
                  </DialogTrigger>
                  {openedModal === "deliveryDate" && <AddDeliveryDate />}
                </Dialog>
              )}
            </S.Content>
          </S.Portal>
        )}
      </S.DropdownMenuRoot>
    </S.Box>
  );
};

export { DropdownMenuInvoices };
