import { SortingParams } from "contracts/Common";
import { FindReleasedInvoices, PaginatedInvoice } from "contracts/Invoice";
import { Pagination } from "contracts/Pagination";
import { usePaginationCache } from "hooks";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
  RefObject,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { PaginateReleasedInvoicesActions as PaginateActions } from "store/ducks/releasedInvoices";

export type ModalTypes = "justificativas" | "prazo" | "deliveryDate" | undefined;

interface ReleasedContextType {
  releasedInvoices: PaginatedInvoice[];
  query: FindReleasedInvoices;
  pagination: Pagination | undefined;
  loading: boolean;
  refModal: RefObject<HTMLDivElement>;
  selectedList: PaginatedInvoice[];
  openedModal: ModalTypes | null;
  showTrash: boolean;
  changeOpenModal: (type?: ModalTypes) => void;
  clearSelectList: () => void;
  onSelectInvoices: (invoices: PaginatedInvoice[]) => void;
  onRemoveSelecteds: (ids: number[]) => void;
  onToggleSelected: (invoice: PaginatedInvoice) => void;
  onPageChange: (page: number) => void;
  onQueryChange: (newQuery?: FindReleasedInvoices, force?: boolean) => void;
  clearQuery: () => void;
  onSort: (newState: SortingParams) => void;
  reset: () => void;
  resetSelectedList: () => void;
}

export const ReleasedContext = createContext({} as ReleasedContextType);

interface ReleasedInvoicesProviderProps {
  children: ReactNode;
}

const INITIAL_QUERY_STATE: FindReleasedInvoices = {
  page: 1,
  limit: 10,
};

export function ReleasedInvoicesProvider({
  children,
}: ReleasedInvoicesProviderProps) {
  const { paginationCache, updatePaginationCache, handleSort } =
    usePaginationCache<FindReleasedInvoices>("releasedInvoices");
  const [selectedList, setSelectedList] = useState<PaginatedInvoice[]>([]);
  const [showModal, setShowModal] = useState<ModalTypes | null>(null);
  const refModal = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState<FindReleasedInvoices>({
    ...INITIAL_QUERY_STATE,
    ...paginationCache,
  });
  const dispatch: AppDispatch = useDispatch();

  const showTrash = useMemo(
    () => JSON.stringify(query) !== JSON.stringify(INITIAL_QUERY_STATE),
    [query]
  );

  const changeOpenModal = useCallback((type?: ModalTypes) => {
    setShowModal(type ? type : null);
  }, []);

  const {
    data: releasedInvoices,
    pagination,
    loading,
  } = useSelector((state: RootState) => state.paginateReleasedInvoices);

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback(
    (newQuery?: FindReleasedInvoices, force = false): void => {
      if (newQuery?.invoiceNumber) {
        newQuery.invoiceNumber = newQuery.invoiceNumber.trim();
      }

      const newData = { ...query, ...newQuery };
      if (JSON.stringify(newData) !== JSON.stringify(query) || force) {
        setQuery((prev) => ({ ...prev, ...newQuery, page: 1 }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [query]
  );

  const clearQuery = useCallback(() => setQuery(INITIAL_QUERY_STATE), []);

  function reset() {
    dispatch(PaginateActions.reset());
    updatePaginationCache(query);
  }

  const onSort = useCallback((newState: SortingParams) => {
    handleSort(query, newState, setQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSelectList = useCallback(() => {
    setSelectedList([]);
  }, []);

  const onSelectInvoices = useCallback((invoices: PaginatedInvoice[]) => {
    setSelectedList((prev) => {
      const prevIDs = prev.map((invoice) => invoice.id);
      return [
        ...prev,
        ...invoices.filter((invoice) => !prevIDs.includes(invoice.id)),
      ];
    });
  }, []);

  const onRemoveSelecteds = useCallback((ids: number[]) => {
    setSelectedList((prev) =>
      prev.filter((invoice) => !ids.includes(invoice.id))
    );
  }, []);

  const onToggleSelected = useCallback((invoice: PaginatedInvoice) => {
    setSelectedList((prev) => {
      const hasOnList = prev.find(
        (invoiceFind) => invoiceFind.id === invoice.id
      );

      if (hasOnList) {
        return prev.filter((invoiceFilter) => invoiceFilter.id !== invoice.id);
      }

      return [...prev, invoice];
    });
  }, []);

  const resetSelectedList = () => {
    setSelectedList([])
  }

  useEffect(() => {
    dispatch(
      PaginateActions.request({
        ...query,
        invoiceNumber: query.invoiceNumber?.replaceAll(" ", "|"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <ReleasedContext.Provider
      value={{
        releasedInvoices,
        refModal,
        query,
        pagination,
        loading,
        selectedList,
        openedModal: showModal,
        showTrash,
        changeOpenModal,
        clearSelectList,
        onSelectInvoices,
        onRemoveSelecteds,
        onToggleSelected,
        onPageChange,
        onQueryChange,
        clearQuery,
        onSort,
        reset,
        resetSelectedList
      }}
    >
      {children}
      <div ref={refModal} />
    </ReleasedContext.Provider>
  );
}
